import {useContext} from "react";
import {StoreContext} from "../stores/StoreLoader";

export function useMeritChatEnabled(contentItem?) {
    const {organizationStore} = useContext(StoreContext);

    if (contentItem) {
        return process.env.MERITOCRACY_CHAT_ENABLED && contentItem.meritchat_enabled
    }
    return process.env.MERITOCRACY_CHAT_ENABLED && (organizationStore.currentOrganization.json_data.settings?.meritchat?.enabled ||
        organizationStore.isMyCleverFeed)
}
