import { Component } from "react";
import classNames from "classnames";
import styles from "../../blocks/StandardTheme/styles/VideoBlock.module.scss";

export default class Html5Video extends Component {
    render() {
        const { title, embedLink } = this.props;
        const embedResponsiveItemClassName = classNames({
            [styles.embedResponsiveItem]: true,
        });
        return (
            <video
                className={embedResponsiveItemClassName}
                alt={title}
                src={embedLink}
            />
        );
    }
}
