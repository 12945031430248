import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import FontAwesome from "../../../utilities/FontAwesome";
import styles from "./ContentItemBlock.module.scss"
import {modalStore, StoreContext} from "../../../../stores/StoreLoader";
import {getPlatform} from "../../../../utils/StringUtilities";
import SocialMarkdown from "../../../utilities/SocialMarkdown";
import moment from "moment/moment";
import {isNews, isEvent, isFile, Media, ItemDetails, isMedia} from "../../../schoolfeed/ListItemGroup";
import classNames from 'classnames';
import ImageComponent from "../../../utilities/ImageComponent";
import {
    getAltText,
    getItemImage,
    isCoursework,
    SchoolFeedContentItem,
    IReactiveCourseWorkContentItem,
    IReactiveEventContentItem,
    IReactiveNewsContentItem,
    IReactiveMediaContentItem, IReactiveContentItem,
} from "../../../../stores/SchoolFeedStore";
import {EditButton} from "../../TerracedGridTheme/EditButton";
import {observer} from "mobx-react";
import {useMountedEffect} from "../../../../hooks/useMountedEffect";
import FlatIconSVG from "../../../utilities/FlatIconSVG";
import {SocialItemModalNavigationProps} from "../../../modals/SocialItemModal";

export const handleContentItemClick = (
    itemObj: IReactiveContentItem | IReactiveEventContentItem | IReactiveNewsContentItem | IReactiveCourseWorkContentItem | IReactiveMediaContentItem,
    socialItemProps?: SocialItemModalNavigationProps,
    handleDelete?: (c: ContentItem) => void,
) => {
    if (/drive.google.com/.test(itemObj.third_party_url)) {
        modalStore.addModal({
            type: "file-preview",
            id: itemObj.id,
            itemObj: { // need to spoof a file item here
                ...itemObj,
                json_data: {
                    file: {
                        view_link: itemObj.third_party_url,
                    },
                },
            },
        })
    } else if (isNews(itemObj)) {
        modalStore.addModal({
            type: "news",
            id: itemObj.id,
            itemObj: itemObj,
            handleDelete,
            getItemAtIndex: socialItemProps?.getItemAtIndex,
            totalItemCount: socialItemProps?.totalItemCount,
            initialItemIndex: socialItemProps?.initialItemIndex,
        })
    } else if (isMedia(itemObj)) {
        modalStore.addModal({
            type: "gallery",
            id: itemObj.id,
            itemObj: itemObj,
            handleDelete,
        })
    } else if (isEvent(itemObj)) {
        modalStore.addModal({
            type: "events",
            itemObj: itemObj,
            handleDelete,
            id: itemObj.id,
        });
    }
    else if (isFile(itemObj)) {
        modalStore.addModal({
            type: "file-preview",
            itemObj: itemObj,
            id: itemObj.id,
        });
    }
    else if (isCoursework(itemObj)) {
        modalStore.addModal({
            type: "coursework",
            id: itemObj.id,
            itemObj: itemObj,
            handleDelete,
        });
    }
};

export function ContentItemReadMore(props) {
    const linkClassName = classNames({
        [styles.link]: true,
    })

    return <div>
        <button className={linkClassName} {...props} onClick={props.onClick ? () => props.onClick(props.contentItem) : () => { return }}>
            <span className={styles.button}>Read More</span>
        </button>
    </div>

}

export function getCourseWorkIcons(contentItem) {
    const iconArray: JSX.Element[] = [];

    if (contentItem.json_data.coursework.materials && contentItem.json_data.coursework.materials.length > 0) {
        for (let i=0; i<contentItem.json_data.coursework.materials?.length; i++) {
            if (contentItem.json_data.coursework.materials[i]?.youtubeVideo) {
                iconArray.push(<FontAwesome prefix={"fab"} name={"fa-youtube"} style={{color: 'red'}}></FontAwesome>);
            }
            if (contentItem.json_data.coursework.materials[i]?.driveFile?.driveFile) {
                iconArray.push(<FlatIconSVG name={'drive'}/>);
            }
            if (contentItem.json_data.coursework.materials[i]?.link) {
                iconArray.push(<FontAwesome prefix={"fas"} name={"fa-link"}></FontAwesome>);
            }
            if (contentItem.json_data.coursework.materials[i]?.form) {
                iconArray.push(<FontAwesome prefix={"fas"} name={"fa-rectangle-list"} style={{color: 'purple'}}></FontAwesome>);
            }
        }
        const uniqueIcons = Array.from(new Set(iconArray.map(obj => obj.props.name)))
            .map(name => iconArray.find(obj => obj.props.name === name))
            .sort((a, b) => {
                const nameA = (a && a.props && a.props.name) || '';
                const nameB = (b && b.props && b.props.name) || '';
                return nameA.localeCompare(nameB);
            });

        const courseWorkImageContainerClassName = classNames({
            [styles.courseWorkImageContainer]: true,
            [styles.courseWorkImageContainerGreaterThanTwo]: uniqueIcons.length > 2,
        });

        const image = <ImageComponent alt={"Coursework Image"}
                                      src={require("../../../../../components/assets/images/placeholders/placeholder-coursework.png")}
                                      className={styles.image}
                                      style={{padding: '15%'}}/>
        return <div className={courseWorkImageContainerClassName}>
            {uniqueIcons.length > 0 ?
                uniqueIcons.map((icon, index) => <div key={index}>{icon}</div>)
                : image}
        </div>
    }
    else {
        return null;
    }
}

const ContentItemBlock = observer((props: {
    contentItem: IReactiveEventContentItem | IReactiveNewsContentItem | IReactiveCourseWorkContentItem,
    handleDelete?: (c: ContentItem) => void
    unpublishItem: (item: SchoolFeedContentItem) => void,
    w: number,
    h: number,
    style?: object,
} & SocialItemModalNavigationProps) => {
    const {interfaceStore, userStore} = useContext(StoreContext);
    const attribution = getPlatform(props.contentItem.type);
    const containerRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLDivElement>(null);
    const [blurBottom, setBlurBottom] = useState(false);
    const maxHeight = ["break-point-xs", "break-point-sm", "break-point-md"].includes(interfaceStore.breakpoint) ? 300 : 550;

    const itemTitle = isNews(props.contentItem) ? props.contentItem.title : props.contentItem.title;
    const date = moment(props.contentItem.created_at).format('MMM D, YYYY');

    function determineBlurBottom() {
        if (containerRef.current?.clientHeight && containerRef.current.clientHeight >= maxHeight) {
            setBlurBottom(true);
        }
    }

    useEffect(() => determineBlurBottom,[]);

    const imageSrc = getItemImage(props.contentItem, false);

    const descriptionStyle = useMemo(() => {
        if (imageSrc) {
            return {marginLeft: '15px'};
        }
        return {};
    },[])

    const contentContainerClassName = classNames({
        [styles.contentContainer]: true,
        [styles.contentContainerBlurBottom]: blurBottom && !isEvent(props.contentItem),
        [styles.contentContainerHidden]: !props.contentItem.published,
    })
    const linkClassName = classNames({
        [styles.link]: true,
        [styles.linkWithDate]: isEvent(props.contentItem),
    })

    const descriptionContainerClassName = classNames({
        [styles.descriptionContainer]: true,
        [styles.descriptionContainerBlurBottom]: blurBottom && isEvent(props.contentItem),
    })

    const contentImage = useMemo(() => {
        if (isCoursework(props.contentItem)) {
            return getCourseWorkIcons(props.contentItem);
        }
        else {
            return <>
                {imageSrc && !isEvent(props.contentItem) && <div ref={imageRef} className={styles.imageContainer}>
                    <ImageComponent onLoad={determineBlurBottom} alt={getAltText(props.contentItem)} src={imageSrc} className={styles.image} />
                    {isMedia(props.contentItem) && props.contentItem.json_data.videos?.cover_image_url &&
                        <FontAwesome prefix={'fas'} name={'fa-play-circle'} />}
                </div>}
            </>
        }
    },[])

    function handleClick() {
        handleContentItemClick(props.contentItem, {
            getItemAtIndex: props.getItemAtIndex,
            totalItemCount: props.totalItemCount,
            initialItemIndex: props.initialItemIndex,
        },
                               props.handleDelete)
    }

    return (
        <div className={styles.blockContainer} style={props.style}>
            <div className={styles.container} tabIndex={0} onClick={handleClick} onKeyPress={e => {
                if (e.key === "Enter") handleClick()}}>
                <div ref={containerRef} className={contentContainerClassName} style={{maxHeight : maxHeight}}>
                    <div className={styles.innerContentContainer}>
                        {isEvent(props.contentItem) && <div className={styles.dateMedia}><Media itemObj={props.contentItem}/></div>}
                        {contentImage}
                        {props.contentItem.organization && <div className={`${styles.organizationTitle} notranslate`}>
                            {props.contentItem.organization.title}
                        </div>}
                        <div className={styles.titleContainer}>
                            {itemTitle && <h2>{itemTitle}</h2>}
                        </div>
                        {isCoursework(props.contentItem) && props.contentItem.json_data?.coursework?.dueDate &&
                            <div className = {styles.dateContainer} style={{fontWeight: 'bold', marginBottom: 0}}>
                                Due Date: {moment(props.contentItem.json_data?.coursework?.dueDate).format('MMM D, YYYY')}
                            </div>}
                        {(isNews(props.contentItem) || isCoursework(props.contentItem)) && <div className={styles.dateContainer}>
                            <div>
                                {`Posted: ${date}`}
                            </div>
                        </div>}
                        {isEvent(props.contentItem) && <ItemDetails itemObj={props.contentItem} style={styles.dateLocationContainer}/>}
                        {isFile(props.contentItem) && <ItemDetails itemObj={props.contentItem} style={styles.dateLocationContainer}/>}
                        <div className={descriptionContainerClassName} style={descriptionStyle}>
                            {props.contentItem.description &&
                                <SocialMarkdown platform={attribution} text={props.contentItem.description}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {blurBottom && <ContentItemReadMore className={linkClassName}
                                                contentItem={props.contentItem}
                                                onClick={handleClick}/>}

            {(userStore.isEditor && props.handleDelete) &&
                <EditButton
                    handleDelete={props.handleDelete}
                    contentItem={props.contentItem}
                    className={styles.editButton}
            />}
        </div>
    );
})

export default ContentItemBlock;
